import React from 'react';
import {BrowserRouter as Router, Route,} from 'react-router-dom';
import Navigation from '../Navigation';

import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import SignOutPage from '../SignOut';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import {withStyles} from '@material-ui/core/styles';

import 'typeface-roboto';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';


const App = () => {
  return(
    <Router>
      <div>
        <AppBar position="static">
          <Toolbar>
            <Navigation/>
            <Typography variant="h6" className={withStyles.title} style={{ flex: 1 }}>
              Favourite things
            </Typography>
            <SignOutPage/>
          </Toolbar>
        </AppBar>
      </div>
      <hr />
      <div>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.SIGN_OUT} component={SignOutPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
      </div>
    </Router>
  );}
  
export default withAuthentication(App);