import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

const PasswordForgetPage = () => (
  <div>
    <Typography component="h1" variant="h2" color="textPrimary">PasswordForget</Typography>
    <PasswordForgetForm />
  </div>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {

    const MyButton = styled(Button)({
      border: 0,
      borderRadius: 3,
      color: 'primary',
      height: 48,
      padding: '0 30px',
    });

    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <Container component="main" maxWidth="xs=3">
        <form onSubmit={this.onSubmit}>
          <div>
            <TextField required
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </div>
          <div>
            <MyButton disabled={isInvalid} variant="contained" color="primary" type="submit">
              Reset My Password
            </MyButton>
          </div>
          {error && <p>{error.message}</p>}
        </form>
      </Container>
    );
  }
}
const PasswordForgetLink = () => (
  <Typography component="h1" variant="h5" color="textPrimary">
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </Typography>
);
export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };