import React from 'react';
import Typography from '@material-ui/core/Typography'

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';


const AccountPage = () => {
  const authUser = React.useContext(AuthUserContext);
  return(
      <div>
        <Typography component="h1" variant="h3" color="textPrimary">Account: {authUser.email}</Typography>
        <PasswordForgetForm />
        <PasswordChangeForm />
      </div>);
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(AccountPage);