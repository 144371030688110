import React from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

//TODO Need to understand this better
import {withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import {useHistory} from 'react-router-dom'

export default function Navigation () {

  let history = useHistory();
  let styles = withStyles();

  const authUser = React.useContext(AuthUserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (nav) => {
    setAnchorEl(null);
    history.push(nav);
  };

return(
  <div>
    <IconButton edge="start" className={styles.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
      <MenuIcon />
    </IconButton>
    
          {authUser ? 
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleClose(ROUTES.LANDING)}>Landing</MenuItem>
                <MenuItem onClick={() => handleClose(ROUTES.HOME)}>Home</MenuItem>
                <MenuItem onClick={() => handleClose(ROUTES.ACCOUNT)}>Account</MenuItem> 
                <MenuItem onClick={() => handleClose(ROUTES.ADMIN)}>Admin</MenuItem> 
            </Menu>
          :
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => handleClose(ROUTES.LANDING)}>Landing</MenuItem>
            <MenuItem onClick={() => handleClose(ROUTES.SIGN_IN)}>Sign In</MenuItem>
          </Menu>}
      
  </div>)
};
