import React from 'react';
import Typography from '@material-ui/core/Typography'
const Landing = () => (
  <div>
    <Typography component="h1" variant="h3" color="textPrimary">Landing component. V4.</Typography >
    <Typography variant="h5" color="textSecondary" paragraph>
      This is a the page that anyone can land on.
    </Typography>
  </div>
);
export default Landing;