import React from 'react';

import Typography from '@material-ui/core/Typography'
import { withAuthorization } from '../Session';
const HomePage = () => (
  <div>
    <Typography component="h1" variant="h3" color="textPrimary">Home Page</Typography>
    <Typography variant="h5" color="textSecondary" paragraph>
      The Home Page is accessible by every signed in user.
      </Typography>
  </div>
);
const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomePage);