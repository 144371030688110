import React from 'react';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import {useHistory} from 'react-router-dom'

import Button from '@material-ui/core/Button';

const SignOutButton = ({ firebase }) => {

  const authUser = React.useContext(AuthUserContext);
  let history = useHistory();

  const handleClick = event => {
    firebase.doSignOut();
    history.push(ROUTES.LANDING);
  };

  return(
  <Button variant="contained" color="primary" onClick={handleClick} disabled={!authUser}>
      Sign Out
  </Button>
);}
export default withFirebase(SignOutButton);